
import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100vw', // 100% del ancho de la pantalla
  height: '50vh', // 100% del alto de la pantalla
};

const apiKey = 'TU_API_KEY';

// Coordenadas de la empresa
const posicionEmpresa = {
  lat: 40.416775, // Latitud (ejemplo: Madrid, España)
  lng: -3.703790, // Longitud
};

const ContactPage = () => {
  return (
    <div className='contact-section'>
      <h1>Let's talk!</h1>
      <h3>Have a question or an exciting new idea?</h3>
      <form className='contact-form'>
        <div className='form-group'>
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className='form-group'>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className='form-group'>
          <label htmlFor="company">Company</label>
          <input type="text" id="company" name="company" required />
        </div>
        <div className='form-group'>
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" required></textarea>
        </div>
        <div className='form-button'>
           <button type="submit">Send</button>
        </div>
      </form>
    </div>
  );
};

export default ContactPage;

